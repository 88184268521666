/* General Navigation Styles */
nav {
    background: #128277;
    /* background: var(--color-primary); */
    height: 5rem;
    width: 100vw;
    display: grid;
    place-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
}

.nav__toggle-btn {
    display: none;
}

.nav__container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.logo {
    width: 4rem;
    display: flex;
}

.title {
    color: var(--color-gray-100);
    margin-left: 0.5rem;
    margin-top: 0.5rem;
}

.nav__links {
    display: flex;
    gap: 2.5rem;
    align-items: center;
    font-weight: 500;
}

.nav__links a {
    transition: var(--transition);
}

.nav__links a:hover {
    color: var(--color-secondary);
}

.active-nav {
    position: relative;

}

.active-nav::after {
    content: '';
    display: block;
    position: absolute;
    left: calc(50% - 0.6rem);
    width: 1.2rem;
    height: 1.2rem;
    background: #128277;
    /* background: var(--color-primary); */
    transform: rotate(45deg);
    margin-top: 0.9rem;
}

/* Language Selector Styles */
.language-selector {
    display: flex;
    align-items: center;
    /* gap: 0.5rem; */
}

.language-selector select {
    border: none;
    background-color: transparent;
    color: var(--color-gray-300);
    font-size: 1rem;
}

/* ==================== MEDIA QUERIES (MEDIUM and SMALL DEVICES) ==================== */
@media screen and (max-width: 1024px) {
    .nav__toggle-btn {
        display: inline-block;
        background: transparent;
        font-size: 1.8rem;
        cursor: pointer;
    }

    .nav__toggle-btn svg {
        color: var(--color-gray-100);
    }

    .nav__links {
        position: absolute;
        top: 100%;
        right: 0;
        flex-direction: column;
        gap: 0;
        display: none;
    }

    .nav__links li {
        height: 4rem;
        box-shadow: -2rem 2rem 5rem rgba(0, 0, 0, 0.4);
        width: 100%;
    }

    .nav__links li a {
        background: var(--color-primary);
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 1rem 5rem 1rem 3rem;
    }

    .show__nav {
        display: flex;
    }

    .hide__nav {
        display: none;
    }

    /* Language selector in mobile */
    .language-selector {
        /* width: 100%; */
        justify-content: center;
        padding: 1rem;
        background: var(--color-primary);
        border-top: 1px solid var(--color-gray-700);
    }

    .language-selector select {
        font-size: 1.2rem;
        padding: 0.5rem;
        color: var(--color-gray-100);
        background: var(--color-secondary);
        border-radius: 0.25rem;
    }
}
