@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::before,
*::after {
    border: 0;
    margin: 0;
    padding: 0;
    outline: 0;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
}

:root {
    --primary-hue: 210;
    --gray-hue: 240;

    /* Set #004D47 as the primary color */
    --color-primary: #128277;

    /* Keep or update other color values accordingly */
    --color-primary-variant: hsl(var(--primary-hue), 100%, 65%);
    --color-secondary: #ffffff;
    --color-secondary-variant: hsl(var(--gray-hue), 100%, 9%);
    --color-white: hsl(0, 0%, 100%);
    --color-gray-100: hsl(var(--gray-hue), 47%, 94%);
    --color-gray-200: hsl(var(--gray-hue), 26%, 75%);
    --color-gray-300: hsl(var(--gray-hue), 24%, 64%);
    --color-gray-400: hsl(var(--gray-hue), 16%, 41%);
    --color-gray-500: hsl(var(--gray-hue), 44%, 25%);
    --color-gray-600: hsl(var(--gray-hue), 48%, 15%);

    --container-width-lg: 80%;
    --container-width-md: 90%;
    --transition: all 500ms ease;
}


body {
    /* background: var(--color-primary); */
    font-family: 'Montserrat', sans-serif;
    color: var(--color-gray-200);
    line-height: 1.7;
    overflow-x: hidden;
    /* background: var(--color-gray-600); */
    background: #004D47;

}

.container {
    width: var(--container-width-lg);
    margin: 0 auto;
    max-width: 1920px;
    margin-inline: auto;
}

h1,
h2,
h3,
h4,
h5 {
    line-height: 1.2;
    color: var(--color-gray-100);
}

h1 {
    font-size: 3rem;
}

h2 {
    font-size: 2rem;
}

a {
    color: var(--color-gray-100);
}

img {
    display: block;
    object-fit: cover;
    width: 100%
}

.btn {
    color: var(--color-gray-100);
    width: fit-content;
    margin: 0 auto;
    padding: 0.9rem 2rem;
    background: var(--color-primary);
    border-radius: 2rem;
    transition: var(--transition);
}

.btn:hover {
    background: var(--color-secondary);
    color: var(--color-gray-600);
}

.btn.lg {
    padding: 1.2rem 3rem;
    border-radius: 3rem;
    font-size: 1.1rem;
}

.btn.sm {
    padding: 0.4rem 1.2rem;
    font-size: 0.9rem;
}

.btn.sm:hover {
    background: var(--color-gray-100);
}


/* MEDIA QUERIES */
/* MEDIUM Devices */
@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-md);
    }

    h1 {
        font-size: 2rem;
    }

    h2 {
        font-size: 1.6rem;
    }


}

/* small Devices */
@media screen and (max-width: 600px) {


    h1 {
        font-size: 2.2rem;
        line-height: 1.3;
    }

    h2 {
        font-size: 1.6rem;
    }


}

.active-nav {
    color: #c8e3da;
    font-size: 1.3rem;
    /* background-color: #007bff; */
    /* Example active background */
}

.nav__language-selector {
    position: relative;
}

.language-toggle-btn {
    background: none;
    gap: 10px;

    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
}

.language-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 1000;
}

.language-dropdown li {
    padding: 8px 16px;
    cursor: pointer;
}

.language-dropdown li:hover {
    background-color: #f0f0f0;
}



.language-selector svg {
    margin-right: 5px;
}

.language-selector select {
    background: transparent;
    border: none;
    color: inherit;
    font-size: inherit;
    cursor: pointer;
}

.language-selector {
    display: flex;
    align-items: center;
    /* margin-left: 20px; */
}

.language-selector svg {
    margin-right: 5px;
}

.language-selector select {
    background: transparent;
    border: none;
    color: inherit;
    font-size: inherit;
    cursor: pointer;
}

footer {
    /* background-color: #f8f9fa; */
    padding: 2rem 0;
    color: #333;
}



.footer__socials {
    margin-top: 1rem;
}

.footer__container article {
    max-width: 300px;
}

.footer__container .footer__ {
    margin-left: auto;
    /* Push permalinks to the right */
    text-align: right;
}

.footer__container .footer__ h4,
.footer__container .footer__ a {
    margin-bottom: 1rem;
    display: block;
}

.footer__copyright {
    text-align: center;
    margin-top: 1rem;
}

.swservice {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.detailbox {
    height: 200px;
    width: 200px;
    background-color: #007bff;
    border-radius: 10px;
}
.worpress{
    height: 120px;
    width: 100px;
}
.appexample{
    height: 23rem;
    width: auto;
}
.androidex{
    height: 500px;
    width: 500px;
}
/* Add this CSS to your styles */
.dropdown-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease, opacity 0.3s ease;
    opacity: 0;
  }
  
  .dropdown-content.open {
    max-height: 200px; /* Set to a height that fits your content */
    opacity: 1;
  }
/* Styles for the contact card */
.contact-card {
    /* background-color: #fff; */
    background-color: #004D47;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px; /* Increase the width */
    width: 100%;
    margin-left: auto; /* Align to the right */
    text-align: center;
}

/* Form styles */
.contact-form .form-group {
    margin-bottom: 15px;
}

.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

.contact-form textarea {
    resize: vertical;
    min-height: 100px;
}

.submit-btn {
    background-color: #3d798b;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-btn:hover {
    background-color: #0056b3;
}

/* Adjustments for footer container */
.footer__container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

/* Footer socials alignment */
.footer__socials a {
    margin-right: 10px;
    color: #333;
}

.footer__socials a:hover {
    color: #007bff;
}

/* Copyright section */
.footer__copyright {
    margin-top: 20px;
    text-align: center;
    width: 100%;
}


/* Modal.css */
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    text-align: center;
}

.close-btn {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease;
}

.close-btn:hover {
    background-color: #0056b3;
}
