.main__header {
    height: calc(100vh - 3rem);
    width: 100vw;
    display: grid;
    place-items: center;
    margin-top: 3rem;
}

.main__header-container {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: space-between;
    gap: 6rem;
}

.main__header-left {
    margin-top: -3rem;

}

.main__header-left h4 {
    margin-bottom: 1rem;
    /* color: #ffeb3b; */
    color: var(--color-secondary);
    font-weight: 400;
}

.main__header-left p {
    margin: 1rem 0 3.5rem;
    font-size: 1.1rem;
}


.main__header-right {
    display: grid;
    place-items: center;
    position: relative;
}

.main__header-circle {
    width: 25rem;
    height: 25rem;
    background: linear-gradient(75deg, var(--color-secondary), transparent);
    border-radius: 50%;
    position: absolute;
    transition: var(--transition);
    /* filter:blur(15px); */
    /* transition: var(--transition);
    animation: circle 10s linear infinite;
    animation-delay: 2s; */
}

.main__header-image {
    position: relative;
}

.main__header-right:hover .main__header-circle {
    filter: blur(15px);
    /* animation: circle 10s linear infinite; */
}

/* Programs */

.programs {
    margin-top: 4rem;
}

.programs__wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 3rem;
    margin-top: 4rem;
}

.programs__program a {
    margin-top: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.programs__program:hover a {
    background: var(--color-secondary);
    color: var(--color-gray-600);
}

/* Programs */

/* Services */

/* Programs */
.services {
    margin-top: 4rem;
}

.services__wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 3rem;
    margin-top: 4rem;
}

.services__program a {
    margin-top: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.services__program:hover a {
    background: var(--color-secondary);
    color: var(--color-gray-600);
}

/* Add this media query for mobile responsiveness */
@media (max-width: 768px) {
    .services__wrapper {
        grid-template-columns: 1fr; /* Display items in a single column on mobile */
        gap: 1.5rem; /* Reduce the gap between items */
    }
}


/*
    Services
*/


/*
    Values
*/

.values__container {
    display: grid;
    grid-template-columns: 38% 50%;
    gap: 12%;
}





.values__image {
    filter: saturate(0.25);
    transition: var(--transition);
    justify-self: center;
    width: 80%;
    height: 100%;
    border-radius: 2%;
    overflow: hidden;
    margin-top: 3rem;   
}

.values__image:hover {
    filter: saturate(1);
}

.values__right>p {
    margin: 1.8rem 0 5rem;
}

.values__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3.4rem 2.4rem;
}


/* FAQs */

.faqs__wrapper {
    margin-top: 4rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem 5rem;
    align-items: flex-start;
}

.faq {
    background: var(--color-gray-500);
    padding: 2rem;
    border-radius: 1rem;
    cursor: pointer;
}


.faq div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.faq__icon {
    background: transparent;
    color: var(--color-gray-100);
    font-size: 1.5rem;
}

.faq p {
    margin-top: 1.5rem;
}

/* Testimonials */

.testimonials {
    width: 50rem;
    margin-inline: auto;
}

.testimonials__head {
    justify-content: center;
}

.card.testimonial {
    position: relative;
    text-align: left;
    margin-top: 5rem;
}

.testimonial__avatar {
    width: 4rem;
    height: 4rem;
    border: 2px solid transparent;
    border-radius: 1.5rem;
    overflow: hidden;
    position: absolute;
    top: -2rem;
    left: calc(50% - 2rem);
    box-shadow: 0 1.5rem 2rem rgba(26, 26, 54, 0.4);
    transition: var(--transition);

}

.testimonial:hover .testimonial__avatar {
    border-radius: 50%;
    border-color: var(--color-gray-400);
}


.testimonial__quote {
    font-style: italic;
    margin: 1rem 0 2rem;
}

.card small.testimonial__title {
    margin-top: 0.3rem;
}

.testimonials__btn-container {
    width: fit-content;
    margin: 2.5rem auto 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.testimonials__btn {
    background: transparent;
    cursor: pointer;
}

.testimonials__btn svg {
    fill: var(--color-primary);
    font-size: 0.8rem;
    width: 3rem;
    height: 3rem;
}

/* Testimonials */

/* Footer */

footer {
    background: var(--color-primary);
    margin-top: 7rem;
    padding-top: 3rem;
    font-size: 0.9rem;
    color: var(--color-gray-100);
}

.footer__container {
    display: grid;
    grid-template-columns: 26rem 1fr 1fr 1fr;
    gap: 6rem;
}


.footer__container article p {
    margin-top: 0.5rem;
}

.footer__container article h4 {
    margin-bottom: 0.6rem;
    font-size: 1rem;
}

.footer__socials {
    margin-top: 1.5rem;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.footer__socials a {
    background: var(--color-gray-100);
    padding: 0.7rem;
    border: 1px solid transparent;
    border-radius: 0.6rem;
    transition: var(--transition);
}

.footer__socials a svg {
    color: var(--color-gray-600);
}

.footer__socials a:hover {
    background: transparent;
    border-color: var(--color-gray-100);
}

.footer__copyright {
    color: var(--color-gray-100);
    text-align: center;
    padding: 1.5rem 0;
    /* border-top: 2px solid var(--color-primary-variant); */
    margin-top: 5rem;
}


form input, form textarea {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}

form button {
    padding: 0.5rem 1rem;
    background-color: #d0e2f6;
    color: rgb(3, 3, 3);
    border: none;
    border-radius: 2rem;
    cursor: pointer;
}

/* Footer */
.card.values__value {
    padding-top: 3.5rem;
    text-align: left;
    position: relative;

}



.card.values__value span {
    position: absolute;
    top: -1.5rem;
}

section {
    margin-top: 0;
    /* Remove the margin */
    padding-top: 5rem;
    /* Add padding to create space if needed */
}


.section__head {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.section__head span {
    background: var(--color-primary);
    padding: .8rem;
    border-radius: 1rem;
    color: var(--color-secondary);
    font-size: 1.5rem;
}


.card {
    background: #1B4B5A;
    /* background: var(--color-gray-500); */
    border: 2px solid transparent;
    text-align: center;
    padding: 3rem 2rem;
    border-radius: 0 3rem 0 3rem;
    transition: var(--transition);
}

.card:hover {
    background: transparent;
    border-color: var(--color-gray-400);
    cursor: default;
}

.card span {
    width: 3rem;
    height: 3rem;
    background: var(--color-primary);
    font-size: 1.5rem;
    border-radius: 1rem;
    padding: 0.8rem;
    display: grid;
    margin-inline: auto;
    place-items: center;
    margin-bottom: 1.5rem;
    transition: var(--transition);
}

.card:hover span {
    background: var(--color-secondary);
    color: var(--color-gray-600);
}

.card small {
    margin-top: 1rem;
    display: block;
    font-weight: 300;
}

/* MEDIA QUERIES */
/* MEDIUM Devices */
@media screen and (max-width: 1024px) {

    /* Main Header */
    .main__header {
        height: fit-content;
        padding: 12rem 0;
    }

    .main__header-container {
        gap: 0;
    }

    .main__header-circle {
        width: 16rem;
        height: 16rem;
    }


    /* Main Header */

    /* Programs */
    .programs__wrapper {
        grid-template-columns: 1fr 1fr;

    }

    .values__container {
        grid-template-columns: 1fr;
        justify-content: center;
        gap: 4rem;
    }

    .values__wrapper {
        gap: 4rem 3rem;
    }

    .values__image {
        display: none;
    }


    .faqs__wrapper {
        grid-template-columns: 1fr;
    }

    .testimonials {
        width: 75%;
    }

    .footer__container {
        grid-template-columns: 1fr 1fr;
        gap: 4rem;
    }

    /* Small Devices */
    @media screen and (max-width: 600px) {
        .main__header {
            margin-top: 0;
            padding: 12rem 0 0;
            height: 100vh;
        }

        .main__header-image,
        .main__header-circle {
            display: none;
        }

        .main__header-container {
            grid-template-columns: 1fr;
        }

        .programs__wrapper {
            grid-template-columns: 1fr;
            gap: 2rem;
            margin-top: 3rem;
        }

        .programs__program {
            width: 84%;
            margin: 0 auto;
        }

        .values__wrapper {
            grid-template-columns: 1fr;
            gap: 3rem;
        }

        .card.values__value {
            width: 84%;
            margin-inline: auto;
        }

        .faqs__wrapper {
            gap: 1rem;
            margin-top: 3rem;
        }

        .testimonials {
            width: 100%;
        }

        footer {
            margin-top: 7rem;
        }

        .footer__container {
            grid-template-columns: 1fr;
            gap: 3rem;
        }

        .footer__container article {
            align-items: center;

        }

        .footer__container article p {
            text-align: center;
        }

        .footer__1 p {
            margin: 1rem 0 3rem;
        }
    }
}